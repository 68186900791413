/* eslint-disable react/forbid-prop-types */
import React, { useState } from "react"
import dynamic from "next/dynamic"
import Image from "next/image"
import PropTypes from "prop-types"

import { useGlobalContext } from "../../context/layout-context"

const ButtonsGroup = dynamic(() => import("../buttons/buttons-group"), {
  ssr: false,
})
const Title = dynamic(() => import("../title/title"), { ssr: true })
const HeroText = dynamic(() => import("../contentful/hero-text"), { ssr: true })
const ButtonPlayVideo = dynamic(() => import("../buttons/button-play-video"), {
  ssr: false,
})
const VideoLoopPlayer = dynamic(() => import("../video/video-loop-player"), {
  ssr: false,
})
const PlayVideo = dynamic(() => import("../buttons/play-video"), { ssr: false })
const VideoBlockPlayer = dynamic(() => import("../video/video-block-player"), {
  ssr: false,
})
const IconClose = dynamic(() => import("../icons/icon-close"), { ssr: false })

// TO DO ADD VIDEO YOUTUBE AND VIMEO
function HeroHalfInnerContent({
  classHero,
  firstTitleTag,
  firstTitle,
  firstTitleBackground,
  firstTitleColor,
  classFirstTitle,
  firstTitleRotated,
  secondTitleTag,
  secondTitle,
  secondTitleBackground,
  secondTitleColor,
  classSecondTitle,
  secondTitleRotated,
  content,
  staticContent,
  image,
  buttons,
  center,
  theme,
  overlay,
  colorButtons,
  appearanceButtons,
  backgroundColor,
  alignContent,
  contentAlign,
  isWorkWithUs,
  container,
  customOverlayColors,
  id,
  source,
  videoLoopId,
  videoLoopFile,
  videoLoopSource,
  popupPlayer,
}) {
  const { globalState } = useGlobalContext()
  const { isMobile, isTablet, isDesktop } = globalState || {}

  const [loopPlaying, setLoopPlaying] = useState(false)
  const [videoPlaying, setVideoPlaying] = useState(false)

  let textAlign
  let buttonsAlign
  let contentPosition

  switch (alignContent) {
    case "Center":
      textAlign = "text-center"
      buttonsAlign = "justify-center"
      break
    case "Center Mobile":
      textAlign = "text-center lg:text-left"
      buttonsAlign = "justify-center lg:justify-start"
      break
    case "Center Desktop":
      textAlign = "text-left lg:text-center"
      buttonsAlign = "justify-start lg:justify-center"
      break
    case "Left":
      textAlign = "text-left"
      buttonsAlign = "justify-start"
      break
    case "Right":
      textAlign = "text-right lg:text-left"
      buttonsAlign = "justify-start"
      break
    default:
      textAlign = "text-left"
      buttonsAlign = "justify-start"
  }

  switch (contentAlign) {
    case "Left":
      contentPosition = "lg:flex-row-reverse"
      break
    case "Right":
      contentPosition = "lg:flex-row"
      break
    default:
      contentPosition = "lg:flex-row-reverse"
  }

  return (
    <article
      className={`${classHero} py-15 lg:pt-30 lg:pb-24 relative overflow-hidden z-0 min-h-400px md:min-h-470px md:flex md:items-center`}
      style={{ backgroundColor: `${backgroundColor || "#000000"}` }}
      data-layer-component="hero-half-inner-content"
    >
      {videoPlaying ? (
        <div className="w-full relative block pb-56pc overflow-hidden">
          <VideoBlockPlayer
            id={id}
            source={source}
            videoPlaying={videoPlaying}
          />
          <button
            type="button"
            onClick={() => setVideoPlaying(false)}
            className="absolute top-4 right-4"
          >
            <IconClose classList="text-white w-8 h-8" />
          </button>
        </div>
      ) : (
        <div className="px-0 lg:px-5 xl:px-15 container">
          <div
            className={`flex items-center flex-wrap ${container} ${textAlign} ${contentPosition} -ml-8`}
          >
            <div className="w-full lg:w-7/12 block mb-0 pl-8">
              {/* Mobile Hero Image */}
              {isMobile && image.mobileImage && image.mobileImage.url && !loopPlaying ? (
                <Image
                  sizes="70vw"
                  width={375}
                  height={248}
                  src={image.mobileImage.url}
                  alt={image.captionAlt || image.mobileImage.title || image.mobileImage.fileName}
                  layout="fill"
                  className="object-cover object-center absolute inset-0"
                  priority
                  placeholder="blur"
                  blurDataURL="data:image/svg+xml;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkWL/qPwAEagJa4rsAIAAAAABJRU5ErkJggg=="
                />
              ) : null}

              {isTablet && image.mobileImage && image.mobileImage.url && !loopPlaying ? (
                <Image
                  sizes="70vw"
                  src={image.mobileImage.url}
                  alt={image.captionAlt || image.mobileImage.title || image.mobileImage.fileName}
                  layout="fill"
                  width={650}
                  height={340}
                  className="object-cover object-center absolute inset-0"
                  priority
                  placeholder="blur"
                  blurDataURL="data:image/svg+xml;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkWL/qPwAEagJa4rsAIAAAAABJRU5ErkJggg=="
                />
              ) : null}

              {/* Desktop Hero Image */}
              {isDesktop && image.image && image.image.url && !loopPlaying ? (
                <Image
                  sizes="80vw"
                  src={image.image.url}
                  alt={image.captionAlt || image.title || image.fileName}
                  layout="fill"
                  className="object-cover object-center absolute inset-0"
                  priority
                  placeholder="blur"
                  blurDataURL="data:image/svg+xml;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkWL/qPwAEagJa4rsAIAAAAABJRU5ErkJggg=="
                />
              ) : null}

              {(videoLoopId && videoLoopSource) ||
              (videoLoopFile && videoLoopSource) ? (
                <VideoLoopPlayer
                  id={videoLoopId || videoLoopFile}
                  source={videoLoopSource}
                  setLoopPlaying={setLoopPlaying}
                />
              ) : null}

              {overlay && (
                <div
                  className={`absolute inset-0 ${
                    customOverlayColors ||
                    "bg-gradient-to-b from-black-10 via-black-60 to-black-70"
                  }`}
                />
              )}
            </div>
            <div
              className={`w-full z-1 pl-8 ${
                center ? "lg:w-full" : "lg:w-5/12"
              }`}
            >
              <div className="pt-12 lg:pt-0 px-5 lg:px-0">
                {firstTitle && (
                  <Title
                    tag={firstTitleTag || "h1"}
                    classList={classFirstTitle}
                    content={firstTitle}
                    colorBackgroundTitle={firstTitleBackground}
                    colorTitle={firstTitleColor}
                    rotated={firstTitleRotated}
                    titleAlign={alignContent}
                  />
                )}

                {popupPlayer ? (
                  <React.StrictMode>
                    {id && source ? (
                      <div className="relative mb-8 flex items-center justify-center">
                        <ButtonPlayVideo
                          id={id}
                          source={source}
                          buttonClassList="w-15 h-15 text-white"
                        />
                      </div>
                    ) : null}
                  </React.StrictMode>
                ) : (
                  <React.StrictMode>
                    {id && source ? (
                      <div className="relative mb-8 flex items-center justify-center">
                        <PlayVideo
                          id={id}
                          source={source}
                          setVideoPlaying={setVideoPlaying}
                          buttonClassList="w-15 h-15 text-white"
                        />
                      </div>
                    ) : null}
                  </React.StrictMode>
                )}

                {secondTitle && (
                  <Title
                    tag={secondTitleTag || "h3"}
                    classList={classSecondTitle}
                    content={secondTitle}
                    colorBackgroundTitle={secondTitleBackground}
                    colorTitle={secondTitleColor}
                    rotated={secondTitleRotated}
                    titleAlign={alignContent}
                  />
                )}
                {content && <HeroText content={content} theme={theme} />}
                {staticContent && (
                  <p className="mb-0 text-white">{staticContent}</p>
                )}
                {buttons && !isWorkWithUs ? (
                  <div className="w-full mt-5">
                    <ButtonsGroup
                      buttons={buttons}
                      appearance={appearanceButtons}
                      color={colorButtons}
                      buttonsAlign={buttonsAlign}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            {buttons && isWorkWithUs ? (
              <div className="w-full mt-5 pl-8">
                <div className="px-5 lg:px-0">
                  <ButtonsGroup
                    buttons={buttons}
                    appearance={appearanceButtons}
                    color={colorButtons}
                    buttonsAlign={buttonsAlign}
                    btnContainerClassList="flex-col lg:flex-row justify-center lg:justify-start"
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </article>
  )
}

HeroHalfInnerContent.propTypes = {
  classHero: PropTypes.string,
  firstTitleTag: PropTypes.string,
  firstTitle: PropTypes.string,
  firstTitleBackground: PropTypes.string,
  firstTitleColor: PropTypes.string,
  classFirstTitle: PropTypes.string,
  firstTitleRotated: PropTypes.bool,
  secondTitleTag: PropTypes.string,
  secondTitle: PropTypes.string,
  secondTitleBackground: PropTypes.string,
  secondTitleColor: PropTypes.string,
  classSecondTitle: PropTypes.string,
  secondTitleRotated: PropTypes.bool,
  content: PropTypes.objectOf(PropTypes.any),
  image: PropTypes.objectOf(PropTypes.any)
}

HeroHalfInnerContent.defaultProps = {
  classHero: "",
  firstTitleTag: "",
  firstTitle: "",
  firstTitleBackground: "",
  firstTitleColor: "",
  classFirstTitle: "",
  firstTitleRotated: false,
  secondTitleTag: "",
  secondTitle: "",
  secondTitleBackground: "",
  secondTitleColor: "",
  classSecondTitle: "",
  secondTitleRotated: false,
  content: {},
  image: {},
}

export default HeroHalfInnerContent
